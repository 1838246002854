<!-- Menu header -->
<div class="navbar-header">
  <ul class="nav navbar-nav flex-row">
    <li class="nav-item mr-auto d-flex">
      <!-- App Branding -->
      <a class="navbar-brand" [routerLink]="['/']">
        <span class="brand-logo">
          <img src="assets/images/logo/favicon.png" class="small-logo" alt="brand-logo" height="36" />
          <img class="large-logo" src="{{ coreConfig.layout.skin == 'dark' ? 'assets/images/logo/logo-dark.png' : 'assets/images/logo/logo.png' }}" alt="">

        </span>
        <!-- <h2 class="brand-text mb-0">{{ coreConfig.app.appName }}</h2> -->
      </a>
    </li>
    <!-- Menu Toggler -->
    <li class="nav-item nav-toggle">

      <a class="nav-link modern-nav-toggle d-none d-xl-block pr-0" (click)="toggleSidebarCollapsible()">
        <i [ngClass]="isCollapsed === true ? 'icon-circle' : 'icon-disc'"
          class="toggle-icon feather font-medium-4 collapse-toggle-icon text-primary"></i>
          
      </a>
      <a class="nav-link modern-nav-toggle d-block d-xl-none pr-0" (click)="toggleSidebar()">
        <i data-feather="x" class="font-medium-4 text-primary toggle-icon"></i>
      </a>

      <!-- <a class="nav-link modern-nav-toggle d-none d-xl-block pr-0" (click)="toggleSidebarCollapsible()">
        <i [ngClass]="isCollapsed === true ? 'icon-circle' : 'icon-disc'"
          class="toggle-icon feather font-medium-4 collapse-toggle-icon text-primary"></i>
      </a>
      <a class="nav-link modern-nav-toggle d-block d-xl-none pr-0" (click)="toggleSidebar()">
        <i data-feather="x" class="font-medium-4 text-primary toggle-icon"></i>
      </a> -->
    </li>
  </ul>
</div>


<!--/ Menu header -->

<!-- Navbar shadow -->
<div class="shadow-bottom" [ngClass]="{ 'd-block': isScrolled }"></div>

<!-- Main menu -->
<div class="main-menu-content" [perfectScrollbar] (scroll)="onSidebarScroll()">

  <!-- <ul class="navigation navigation-main" id="main-menu-navigation" layout="vertical" core-menu></ul> -->

  <ul class="navigation navigation-main" id="main-menu-navigation" data-menu="menu-navigation">

    <!-- <li class=" nav-item">
      <a class="drop__1" href="javascript:void(0)" routerLinkActive="active"
      [routerLinkActiveOptions]="{exact: true}" [routerLink]="['/dashboard']">
        <i class="far fa-circle"></i>{{'menus.dsbrd' | translate}}
      </a>
    </li> -->
    <li class=" nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"><a [routerLink]="['/dashboard']" class="d-flex align-items-center" href="javascript:void(0)"><i
      data-feather="home"></i><span class="menu-title text-truncate" data-i18n="Kanban">{{'menus.dsbrd' |
      translate}}</span></a>
    </li>
    <li class=" nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"><a [routerLink]="['/account-detail']" class="d-flex align-items-center" href="javascript:void(0)"><i
      data-feather="settings"></i><span class="menu-title text-truncate" data-i18n="Kanban">Account Details</span></a>
    </li>
    <li class=" nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"><a [routerLink]="['/addresses']" class="d-flex align-items-center" href="javascript:void(0)"><i
      data-feather="file-text"></i><span class="menu-title text-truncate" data-i18n="Kanban">Addresses</span></a>
    </li>
    <li class=" nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
    [routerLink]="['/two-auth']">
    <a class="d-flex align-items-center" href="javascript:void(0)">
      <i data-feather="smartphone"></i><span class="menu-title text-truncate" data-i18n="Kanban">2 Factor Auth</span></a>
    </li>
    <li class=" nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
    [routerLink]="['/id-verification']">
    <a class="d-flex align-items-center" href="javascript:void(0)">
      <i data-feather="user-check"></i><span class="menu-title text-truncate" data-i18n="Kanban">ID Verification</span></a>
    </li>
    <!-- <li class=" nav-item dasboard_____only"   [class.chevRotate]="showNotifications">
      <a (click)="toggleClass()" class="d-flex align-items-center" href="javascript:void(0)"><i data-feather="home"></i><span
          class="menu-title text-truncate" data-i18n="Dashboards">{{'menus.dsbrd' | translate}}</span><span
          class="badge badge-light-warning badge-pill ml-auto mr-1"></span></a>

      <div class="abs__right__tab" (click)="toggleClass()">
        <img class="select__tab" src="assets/images/sel.png" alt="">
      </div>
      <div class="none-css">
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-more-horizontal"><circle cx="12" cy="12" r="1"></circle><circle cx="19" cy="12" r="1"></circle><circle cx="5" cy="12" r="1"></circle></svg>
      </div>
    </li>
    <div class="drop__cus__side"  [class.show]="showNotifications">
      <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="drop__1" href="javascript:void(0)"
      [routerLink]="['/dashboard/opportunities']">
      <div class="side-s">
      <i class="far fa-circle"></i>{{'menus.opportuuuuuu' | translate}}</div></a>
    </div> -->

    <!-- <li class=" navigation-header"><span data-i18n="Apps &amp; Pages">{{'menus.stng' | translate}}</span>
    </li> -->

    <li class=" nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
      <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="drop__1" href="javascript:void(0)"
      [routerLink]="['/dashboard/opportunities']">
      <span class="menu-title text-truncate"
        data-i18n="File Manager">
      <i class="font-icon fa fa-home"></i>Market Place</span></a>
    </li>
    <!-- <li class=" nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
      <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="drop__1" href="javascript:void(0)"
      [routerLink]="['/portfolio']">
      <span class="menu-title text-truncate"
        data-i18n="box"><i data-feather="box"></i>Portfolio</span></a>
    </li> -->
    

    <li class=" nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
      <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="drop__1" href="javascript:void(0)"
      [routerLink]="['/cart']">
      <span class="menu-title text-truncate"
        data-i18n="File Manager">
      <i class="fa fa-shopping-cart"></i>Cart</span></a>
    </li>

    <!-- <li class=" nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"><a [routerLink]="['/orders']" class="d-flex align-items-center" href="javascript:void(0)"><i
      data-feather="shopping-bag"></i><span class="menu-title text-truncate" data-i18n="Kanban">Orders</span></a>
    </li> -->
    <li class=" nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
    >
    <a [routerLink]="['/history/transaction']" class="d-flex align-items-center" href="javascript:void(0)">
      <i data-feather="shopping-bag"></i><span class="menu-title text-truncate" data-i18n="Kanban">Orders</span></a>
    </li>

    <li class=" nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"><a [routerLink]="['/settings/support']" class="d-flex align-items-center"
      href="javascript:void(0)"><i data-feather="mail"></i><span class="menu-title text-truncate"
        data-i18n="File Manager">{{'menus.spprt' | translate}}</span></a>
    </li>
    <!-- <li class=" nav-item"><a href="javascript:void(0)" class="d-flex align-items-center"
      href="javascript:void(0)"><i data-feather="message-square"></i><span class="menu-title text-truncate"
        data-i18n="File Manager">{{'menus.telegram' | translate}}</span></a>
    </li> -->
    <li class=" nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
      [routerLink]="['/referral']"><a class="d-flex align-items-center" href="javascript:void(0)"><i
          data-feather="check-square"></i><span class="menu-title text-truncate" data-i18n="Todo">{{'menus.shRef' |
          translate}}</span></a>
    </li>
    <!-- <li class=" nav-item" [routerLink]="['/faq']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"><a class="d-flex align-items-center" href="javascript:void(0)"><i
      data-feather="calendar"></i><span class="menu-title text-truncate" data-i18n="Calendar">{{'menus.fq' |
      translate}}</span></a>
    </li> -->
    <li class=" nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"><a [routerLink]="['/settings/account']" class="d-flex align-items-center" href="javascript:void(0)"><i
          data-feather="grid"></i><span class="menu-title text-truncate" data-i18n="Kanban">{{'menus.stngsss' |
          translate}}</span></a>
    </li>
    

    <li class=" nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"><a [routerLink]="['/settings/downloads']" class="d-flex align-items-center"
        href="javascript:void(0)"><i data-feather="save"></i><span class="menu-title text-truncate"
          data-i18n="File Manager">{{'menus.dwnlds' | translate}}</span></a>
    </li>

    <!-- <li class=" nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
      [routerLink]="['/rankings-old']"><a class="d-flex align-items-center" href="javascript:void(0)"><i
          data-feather="award"></i><span class="menu-title text-truncate" data-i18n="Todo">{{'menus.shRefRank' |
          translate}}</span></a>
    </li> -->

   

  </ul>
</div>
<!--/ Main menu -->


<ng-template #referralModal let-modal3>
  <div class="modal-header bg-info">
    <button type="button" [disabled]="deleting" class="close" (click)="closedReferalPopup(modal3)" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body zero-space" tabindex="0" ngbAutofocus>
    <div class="popup__social">
      <p class="popup__social__p">Refer & Earn</p>
      <p class="popup__social__p__1">Invite your friends to SPLITLEVEL, if they sign up, you and
        your friend can get rewards!</p>
      <ul class="popup__social__ul">
        <li>
          <img src="assets/images/pop-1.png" class="pop-1-img" alt="">
          <p class="Send-Invitation">Send Invitation 🤟🏻</p>
          <p class="Send-Invitation-1">Send your referral link to your friend</p>
        </li>
        <li>
          <img src="assets/images/pop-2.png" class="pop-1-img" alt="">
          <p class="Send-Invitation">Registration 👩🏻‍💻</p>
          <p class="Send-Invitation-1">Let them register to our services</p>
        </li>
        <li>
          <img src="assets/images/pop-3.png" class="pop-1-img" alt="">
          <p class="Send-Invitation">Rewards 🎉</p>
          <p class="Send-Invitation-1">Now you and your friend can get rewarded!</p>
        </li>
      </ul>
    </div>
    <div class="popup__social__1">
      <p class="Invite__p">Invite your friends</p>
      <p class="Invite__p_1">Enter your friend’s email address and invite them to join SPLITLEVEL 😍</p>
      <form [formGroup]="userReferralForm">
        <div class="Email_address form-group new-ccc">
          <input type="text" placeholder="Email address..." class="Email-address-input form-control"
            formControlName="email" [ngClass]="{ 'is-invalid': submitted && refForm['email'].errors }" maxlength="40"
            trim />
          <div *ngIf="submitted && refForm['email'].errors" class="invalid-feedback face">
            <span class="for-sub-name" *ngIf="refForm['email'].errors['required']">
              Email is required.
            </span>
            <span class="for-sub-name" *ngIf="refForm['email'].errors['pattern']">
              Please enter valid email address.
            </span>
          </div>
          <button class="Send__bt" [disabled]="isBtnDisabled" (click)="shareReferral(modal3)">Send
            <i class="fa fa-spinner fa-pulse fa-3x fa-fw" style=" font-size: 22px; " *ngIf="isBtnDisabled"></i>
          </button>
        </div>
      </form>
      <div class="Email_address_div">
        <p class="Email_address_p">Share the referral link</p>
        <p class="Email_address_p_1">You can also copy and send it or share it on your social media. 🥳</p>
      </div>
      <div class="ul__bottom">
        <ul>
          <li>
            <input type="text" placeholder="{{affilation_url}}" value="{{affilation_url}}" [disabled]="true" #userinput
              readonly class="ul__bottom__input">
            <a class="Copy-link" href="javascript:void(0)" (click)="copyInputMessage(userinput)">Copy link</a>
          </li>
          <li>
            <a class="social___a" href="https://www.facebook.com/sharer/sharer.php?u=#{{affilation_url}}" target="_blank"><img
                src="assets/images/pop-social.png" alt=""></a>
            <a class="social___a" href="http://www.twitter.com/share?url={{affilation_url}}" target="_blank"><img src="assets/images/pop-social-1.png" alt=""></a>
            <a class="social___a" href="https://www.linkedin.com/share?url={{affilation_url}}" target="_blank"><img src="assets/images/pop-social-2.png" alt=""></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</ng-template>